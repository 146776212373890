@font-face {
    font-family: 'Lato';
    src: url(/fonts/Lato-Regular.woff2) format('woff2'),
         url(/fonts/Lato-Regular.woff) format('woff'),
         url(/fonts/Lato-Regular.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'LatoLight';
    src: url(/fonts/Lato-Light.woff2) format('woff2'),
         url(/fonts/Lato-Light.woff) format('woff'),
         url(/fonts/Lato-Light.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'LatoBold';
    src: url(/fonts/Lato-Bold.woff2) format('woff2'),
         url(/fonts/Lato-Bold.woff) format('woff'),
         url(/fonts/Lato-Bold.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Vollkorn';
    src: url(/fonts/Vollkorn-Regular.woff2) format('woff2'),
         url(/fonts/Vollkorn-Regular.woff) format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
} 

@font-face {
    font-family: 'VollkornBold';
    src: url(/fonts/Vollkorn-Bold.woff2) format('woff2'),
         url(/fonts/Vollkorn-Bold.woff) format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
} 

@font-face {
    font-family: 'VollkornBlack';
    src: url(/fonts/Vollkorn-Black.woff2) format('woff2'),
         url(/fonts/Vollkorn-Black.woff) format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'VollkornItalic';
    src: url(/fonts/Vollkorn-Italic.woff2) format('woff2'),
         url(/fonts/Vollkorn-Italic.woff) format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* hebrew font */
@font-face {
    font-family: 'TaameyFrank';
    src: url(/fonts/subset-TaameyFrankCLM-Medium.woff2) format('woff2'),
         url(/fonts/subset-TaameyFrankCLM-Medium.woff) format('woff'),
         url(/fonts/TaameyFrankCLM-Medium.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: 'TaameyFrank';
    src: url(/fonts/subset-TaameyFrankCLM-Bold.woff2) format('woff2'),
        url(/fonts/subset-TaameyFrankCLM-Bold.woff) format('woff'),
         url(/fonts/TaameyFrankCLM-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}


/* ancient hebrew font */
@font-face {
    font-family: 'AncientHebrew';
    src: url(/fonts/ancheb.ttf) format('truetype');
    font-style: normal;
    font-weight: normal;
}